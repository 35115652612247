import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import { sendPhoto } from "./api";
import Loader from "../Loader";

function CameraComponent(props) {
  const [isLoading, setLoading] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const webcamRef = useRef(null);
  const [response, setResponse] = useState();

  const openCamera = () => {
    props?.setShowCamera(false);
    setIsCameraOpen(true);
  };
  const closeCamera = () => {
    setIsCameraOpen(false);
    props?.setPhoto(null);
    setResponse("");
    props?.setShowCamera(true);
  };

  const takePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    props?.setPhoto(imageSrc);
    setIsCameraOpen(false);
  };

  const submitPhoto = async () => {
    setLoading(true);
    // Here you would send `photo` to your API
    const res = await sendPhoto({ file: props?.photo });
    setResponse(res);
    // closeCamera();
    setLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {response ? (
            <div className="response blue-color">
              <div>
                <div className="preview">
                  <img src={props?.photo} alt="Preview" />
                </div>
              </div>
              <div className="res-container">
                {response?.Name && (
                  <div>
                    <span className="bold-text">Name: </span>
                    <span>{response?.Name}</span>
                  </div>
                )}
                <div>
                  <span className="bold-text">Message: </span>
                  <span>{response?.message || response?.error}</span>
                </div>
                {response?.similarity && (
                  <div>
                    <span className="bold-text">Similarity: </span>
                    <span>{response?.similarity && typeof response?.similarity === "number" ? response?.similarity?.toFixed(1) :response?.similarity }%</span>
                  </div>
                )}
              </div>
              <div className="btnContainer ">
                <button
                  className="takePhotoButton margin-auto"
                  onClick={closeCamera}
                >
                  <span class="lable">Back</span>
                </button>
              </div>
            </div>
          ) : (
            <div className="camera-component">
              <div class="gradient-text" style={{ margin: "1rem 0" }}>
                <span class="apply">Face</span>
                <span style={{ marginLeft: "0.7rem" }} class="to">
                  Recog
                </span>
                <span class="drive">nition</span>
              </div>
              {!isCameraOpen && !props?.photo && (
                <div className="takePhotoContainer">
                  <button onClick={openCamera} className="takePhotoButton">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      viewBox="0 0 24 24"
                      height="24"
                      fill="none"
                      class="svg-icon"
                    >
                      <g
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke="#fff"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path d="m4 9c0-1.10457.89543-2 2-2h2l.44721-.89443c.33879-.67757 1.03131-1.10557 1.78889-1.10557h3.5278c.7576 0 1.4501.428 1.7889 1.10557l.4472.89443h2c1.1046 0 2 .89543 2 2v8c0 1.1046-.8954 2-2 2h-12c-1.10457 0-2-.8954-2-2z"></path>
                        <path d="m15 13c0 1.6569-1.3431 3-3 3s-3-1.3431-3-3 1.3431-3 3-3 3 1.3431 3 3z"></path>
                      </g>
                    </svg>
                    <span class="lable">Take a Photo</span>
                  </button>
                </div>
              )}
              {isCameraOpen && (
                <div className="camera">
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width="100%"
                    videoConstraints={{
                      width: 352,
                      height: 240,
                      facingMode: "user",
                    }}
                  />
                  <div className="camera-buttons">
                    <button className="takePhotoButton" onClick={takePhoto}>
                      <span class="lable">Click Photo</span>
                    </button>
                    <button
                      className="takePhotoButtonCancel"
                      onClick={closeCamera}
                    >
                      <span class="lable">Cancel</span>
                    </button>
                  </div>
                </div>
              )}
              {props?.photo && (
                <div className="preview">
                  <img src={props?.photo} alt="Preview" />
                  <div className="preview-buttons">
                    <button className="takePhotoButton" onClick={submitPhoto}>
                      <span class="lable">Submit</span>
                    </button>
                    <button
                      className="takePhotoButtonCancel"
                      onClick={closeCamera}
                    >
                      <span class="lable">Cancel</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CameraComponent;
