import axios from "axios";

export const sendPhoto = async (data) => {
  const axiosConfig = {
    headers: {
      Authorization: `null`, // Replace with your actual token or remove this line
    },
  };
  try {
    const response = await axios.post(
      "https://dev.service.face.innovationm.com/verify",
      data,
      axiosConfig
    );

    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
    return "An error occurred while fetching the response.";
  }
};

export const uploadPhoto = async (data) => {
  const axiosConfig = {
    headers: {
      Authorization: `null`, // Replace with your actual token or remove this line
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    const response = await axios.post(
      "https://dev.service.face.innovationm.com/upload_profile",
      data,
      axiosConfig
    );

    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
    return "An error occurred while fetching the response.";
  }
};
