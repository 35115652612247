import React, { useState } from "react";
import { uploadPhoto } from "../FaceRecognition/api";
import Loader from "../Loader";
export default function UploadPhoto({ setShowFaceRecognition }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState("");
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setError("");
        setSelectedFile(file);
        const fileReader = new FileReader();
        fileReader.onload = () => {
          setPreview(fileReader.result);
        };
        fileReader.readAsDataURL(file);
      } else {
        setError("Please upload a JPEG or PNG image.");
        setSelectedFile(null);
        setPreview(null);
      }
    }
  };

  const handleUpload = async () => {
    setShowFaceRecognition(false);
    if (!selectedFile || !name) {
      setError("Fields Required");
      return;
    }
    setLoading(true);
    setError(null);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("name", name);
    // Handle the file upload here, e.g., sending to a server
    const res = await uploadPhoto(formData);

    setResponse(res);

    setLoading(false);
  };

  const back = () => {
    setSelectedFile(null);
    setName("");
    setPreview(null);
    setResponse(null);
    setShowFaceRecognition(true);
  };

  return (
    <div style={styles.container}>
      {loading ? (
        <Loader />
      ) : response ? (
        <div className="blue-color center-div">
          {response?.message ? (
            <>
              {preview && (
                <div style={styles.previewContainer}>
                  <img src={preview} alt="Preview" style={styles.preview} />
                </div>
              )}
              <h5><span><b>Message:</b> </span><span>{response?.message}</span></h5>{" "}
              <div>
                <button
                  style={{ marginTop: "1rem" }}
                  onClick={back}
                  className="takePhotoButton"
                >
                  <span className="lable">Back</span>
                </button>
              </div>
            </>
          ) : (
            <div>
              {preview && (
                <div style={styles.previewContainer}>
                  <img src={preview} alt="Preview" style={styles.preview} />
                </div>
              )}
              <h5><span><b>Message:</b> </span><span>No faces detected</span></h5>
              <button
                style={{ marginTop: "1rem" }}
                onClick={back}
                className="takePhotoButton"
              >
                <span className="lable">Back</span>
              </button>
            </div>
          )}
        </div>
      ) : (
        <>
          <div class="gradient-text" style={{ margin: "1rem 0" }}>
            <span class="apply">Upload</span>
            <span style={{ marginLeft: "0.7rem" }} class="to">
              Pro
            </span>
            <span class="drive">files</span>
          </div>
          <input
            type="file"
            accept="image/jpeg, image/png"
            onChange={handleFileChange}
            style={styles.fileInput}
            className="fileImgInput"
          />

          <div style={{ display: "flex", gap: "1rem" }}>
            <div className="white-color">
              <strong>Name:</strong>{" "}
            </div>
            <div>
              <input
                placeholder="Enter name"
                className="uploadName"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          {error && <p style={styles.error}>{error}</p>}
          {preview && (
            <div style={styles.previewContainer}>
              <img src={preview} alt="Preview" style={styles.preview} />
            </div>
          )}
          <button
            onClick={handleUpload}
            style={{ marginTop: "1rem" }}
            className="takePhotoButton"
          >
            <span className="lable">Upload</span>
          </button>
        </>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 20px 20px 20px",
    borderRadius: "10px",
  },
  fileInput: {
    marginBottom: "16px",
    color: "#435B8E",
  },
  previewContainer: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  preview: {
    width: "200px",
    height: "200px",
    objectFit: "cover",
    borderRadius: "10px",
  },
  uploadButton: {
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "5px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  },
  error: {
    color: "red",
  },
  white: {
    color: "white",
  },
};
