import { useState } from "react";
import CameraComponent from "./FaceRecognition/Camera";
import UploadPhoto from "./UploadPhoto/UploadPhoto";

function App() {
  const [photo, setPhoto] = useState(null);
  const [showFaceRecognition, setShowFaceRecognition] = useState(true);
  const [showCamera, setShowCamera] = useState(true);

  return (
    <div className="app">
      {showCamera && (
        <UploadPhoto setShowFaceRecognition={setShowFaceRecognition} />
      )}
      {showFaceRecognition && (
        <CameraComponent
          photo={photo}
          setPhoto={setPhoto}
          setShowCamera={setShowCamera}
        />
      )}
    </div>
  );
}

export default App;
